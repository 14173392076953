/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import Layout from "@layout";
import PageBreadcrumb from "@components/pagebreadcrumb";
import { graphql } from "gatsby";
import { Row, Container, Col } from "react-bootstrap";
import LargeSinglePosts from "../../components/large-single-post";
import LatestPostArea from "../../container/latest-post";
import { BlogDetailsArea, BlogDetailsRightSidebar } from "./style";
import { useEffect, useState } from "react";
const CategorisPosts = ({ data, location, pageContext }) => {
    const new_Data = data.allStrapiArticle.nodes;
    return (
        <Layout>
            <PageBreadcrumb pageContext={pageContext} location={location} />
            <BlogDetailsArea>
                <Container>
                    <Row className="gx-5">
                        <Col lg={8} md={7}>
                            <Row>
                                {new_Data &&
                                    new_Data.map((item, i) => {
                                        return (
                                            <Col lg={12} key={i}>
                                                <LargeSinglePosts
                                                    title={item.title}
                                                    thume_image={
                                                        item.image.localFile
                                                    }
                                                    categories={
                                                        item.category.slug
                                                    }
                                                    body={item.description}
                                                    date={item.updated_at}
                                                    slug={item.slug}
                                                    dateSlug={item.updated_at}
                                                    authorslug={
                                                        item.author.slug
                                                    }
                                                />
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Col>
                        <Col lg={4} md={5}>
                            <BlogDetailsRightSidebar
                                sx={{ mt: "-40px !important" }}
                            >
                                <LatestPostArea />
                            </BlogDetailsRightSidebar>
                        </Col>
                    </Row>
                </Container>
            </BlogDetailsArea>
        </Layout>
    );
};

CategorisPosts.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
};

export default CategorisPosts;

export const sideLatestPostsQuery = graphql`
    query MyQuery($slug: String) {
        allStrapiArticle(
            filter: { category: { slug: { eq: $slug } } }
            sort: { fields: [updated_at], order: DESC }
        ) {
            nodes {
                title
                slug
                description
                updated_at
                author {
                    name
                    slug
                }
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 850
                                height: 400
                                quality: 100
                            )
                        }
                    }
                }
                category {
                    name
                    slug
                }
            }
        }
    }
`;
