import { Link } from "gatsby";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
    SingleLatestPost,
    LatestPostThum,
    LatestPostContent,
    Title,
    LatestPostMeta,
    PostDate,
} from "./style";

const SideLatestPosts = ({ title, date, thume_image, slug, dateSlug }) => {
    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
    date = formatDate(date);
    dateSlug = formatDate(dateSlug);
    const image = getImage(thume_image);
    return (
        <SingleLatestPost>
            <LatestPostThum>
                <Link to={`/${slug}/`}>
                    <GatsbyImage image={image} alt="" />
                </Link>
            </LatestPostThum>
            <LatestPostContent>
                <Title>
                    <Link to={`/${slug}/`}>{title}</Link>
                </Title>
                <LatestPostMeta>
                    <PostDate>
                        <i className="icofont-ui-calendar"></i>
                        <Link to={`/date/${dateSlug}`}>{date}</Link>
                    </PostDate>
                </LatestPostMeta>
            </LatestPostContent>
        </SingleLatestPost>
    );
};

export default SideLatestPosts;
