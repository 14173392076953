import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SideLatestPosts from "../../components/side-latest-post";
import { LatestPostInnerWrap } from "./style";
const LatestPostArea = (props) => {
    const sideLatestPostsQuery = useStaticQuery(graphql`
        query latestPost {
            allStrapiArticle(
                sort: { fields: [updated_at], order: DESC }
                limit: 6
            ) {
                edges {
                    node {
                        author {
                            name
                        }
                        id
                        title
                        slug
                        image {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 84
                                        height: 84
                                        quality: 100
                                    )
                                }
                            }
                            updated_at
                        }
                    }
                }
            }
        }
    `);

    const sideLatestPostsData = sideLatestPostsQuery.allStrapiArticle.edges;

    return (
        <LatestPostInnerWrap>
            <div className="section-title">
                <h3>Latest Post</h3>
            </div>
            {sideLatestPostsData &&
                sideLatestPostsData.map((latestPosts, i) => {
                    return (
                        <SideLatestPosts
                            key={i}
                            title={latestPosts.node.title}
                            thume_image={latestPosts.node.image.localFile}
                            date={latestPosts.node.image.updated_at}
                            dateSlug={latestPosts.node.image.updated_at}
                            slug={latestPosts.node.slug}
                        />
                    );
                })}
        </LatestPostInnerWrap>
    );
};

LatestPostArea.propTypes = {};

export default LatestPostArea;
